import React, { useEffect, useState } from "react";
import useRequest from "src/utils/useRequest";
import { useSetPage } from "src/context/siteContext";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { AvailabilityNav } from "src/components/availability/nav";
import { AvailabilityHeader } from "src/components/availability/header";
import { UnitGrid } from "src/components/availability/unit-grid";
import { LeasingMenu } from "src/components/global/leasing-menu";
import { LoadingMessage } from "src/components/global/loading-message";
import axios from "axios";
//re-push

// Entrata API vars
const entrataBaseURL = process.env.GATSBY_ENTRATA_API_URL;
const entrataUsername = process.env.GATSBY_ENTRATA_USERNAME as string;
const entrataPassword = process.env.GATSBY_ENTRATA_PASSWORD as string;

const TESTDATA = require("../test-data.json");

interface AvailabilityProps {
  buildingSlug: string;
  unitCategorySlug: string;
  pageContext: any;
  location: any;
  path: string;
}
const Availability = ({
  buildingSlug,
  unitCategorySlug,
  pageContext,
  location,
  path
}: AvailabilityProps) => {
  const setPage = useSetPage();
  useEffect(() => {
    setPage("availability");
  }, [0]);

  const {
    propertyId,
    applicationUrl,
    unitCategories,
    allUnitsHeadline,
    generalRentDiscount,
    phase1_1RentDiscount,
    phase1_2RentDiscount,
    common
  } = pageContext;
  const [results, setResults] = useState<readonly { beds: number, isColiving: boolean }[]>();

  const categories = [
    ...unitCategories,
    {
      bedroomCount: 1,
      slug: {
        current: "co-living",
        _type: "slug",
      },
      title: "Co-Living",
      _key: "co-living",
      _type: "buildingUnitCategory",
    }
  ];

  const filterResult = results && results.filter(
    ({ isColiving, beds }) => {
      switch (unitCategorySlug) {
        case 'studio':
          return !isColiving && beds === 0;
        case '1-bedroom':
          return !isColiving && beds === 1;
        case '2-bedroom':
          return !isColiving && beds === 2;
        case '3-bedroom':
          return !isColiving && beds === 3;
        case 'co-living':
          return isColiving;
        default:
          return true;
      }
    }
  ).sort((a, b) => a.beds - b.beds);

  const unitCategory = categories.find(
    (unitCategory: any) => unitCategory.slug.current === unitCategorySlug
  );

  useEffect(() => {
    async function axiosTest() {
      // const response = await axios.get(`${process.env.GATSBY_RENTCAFE_API_URL}`, { params: { apiToken: process.env.GATSBY_RENTCAFE_API_TOKEN,propertyCode:process.env.GATSBY_RENTCAFE_API_PROPERTY_CODE,requestType:process.env.GATSBY_RENTCAFE_API_REQUEST_TYPE } });
      const [colivingResponse, otherResponse] = await Promise.all([
        await axios.get('https://yardi.nwgapi.com/units.php?apitoken=2378c066-394a-4736-abe9-6ef5ae5ae11a&companycode=C00000042424&propertycode=p1775478'),
        await axios.get('https://yardi.nwgapi.com/units.php?apitoken=2378c066-394a-4736-abe9-6ef5ae5ae11a&companycode=C00000042424&propertycode=p1541264')
      ]);


      setResults([
        ...colivingResponse.data.map((i: any) => ({ ...i, isColiving: true })),
        ...otherResponse.data.map((i: any) => ({ ...i, isColiving: false })),
      ]);
    }
    axiosTest();
  }, []);
  return (
    <RevealOnMount location={location}>
      <SEO pagePath={`/availability/${buildingSlug}`} metaInfo={{}} />
      <AvailabilityNav
        buildingSlug={buildingSlug}
        unitCategories={unitCategories}
        common={common}
      />
      <LeasingMenu
        buildingSlug={buildingSlug}
        applicationUrl={applicationUrl}
      />

      <div style={{ minHeight: "100vh" }}>
        <RevealOnMount location={location}>
          <AvailabilityHeader
            slug={unitCategorySlug}
            unitCategory={unitCategory}
            headline={allUnitsHeadline}
          />
          {results && (
            <UnitGrid
              propertyId={propertyId}
              res={filterResult}
              unitCategorySlug={unitCategorySlug}
              rentDiscounts={{
                generalRentDiscount,
                phase1_1RentDiscount,
                phase1_2RentDiscount
              }}
            />
          )}
        </RevealOnMount>
      </div>
    </RevealOnMount>
  );
};

export default Availability;
